<template>
  <Drawer id="right-drawer" position="right" :style="{ zIndex: 9999 }">
    <div id="menu-items" class="flex flex-column">
      <p>VOTO</p>
      <a href="/">{{ $t("menu.start") }}</a>
      <a target="_blank" href="https://portal.voto.vote/">{{
        $t("menu.portal")
      }}</a>
      <a v-if="showTutorialMenu" @click="showTutorial()">{{
        $t("menu.tutorial")
      }}</a>
      <p>{{ $t("menu.about") }}</p>
      <a target="_blank" href="https://voto.vote/faq">{{ $t("menu.faq") }}</a>
      <a target="_blank" href="https://voto.vote/">{{ $t("menu.what") }}</a>
      <a target="_blank" href="https://voto.vote/datenschutz">{{
        $t("menu.data_protection")
      }}</a>
      <a target="_blank" href="https://voto.vote/impressum">{{
        $t("menu.legal_notice")
      }}</a>
      <p>{{ $t("menu.languages") }}</p>
      <a
        href="#"
        v-for="l in filteredLanguageOptions"
        :key="l.value"
        @click="changeLanguage(l.value)"
        >{{ l.name }}</a
      >
    </div>

    <div class="bottom">
      <div class="version-container text-gray">
        <div>2024-11-07-1587219</div>
      </div>
    </div>

    <div>
      <b-button
        v-b-modal.change-appliction-modal
        class="language-button"
        variant="light"
      >
      </b-button>
    </div>
  </Drawer>
</template>
<script>
import { OnboardTour, VOTOTour } from "../../utils/tour.js";
import i18n from "@/i18n";
import ElectionMixin from "@/mixins/ElectionMixin.vue";

export default {
  name: "RightDrawer",
  mixins: [ElectionMixin],
  data() {
    return {
      showTutorialMenu: false,
      availableLanguagesFromApi: [], // Added this for language handling
    };
  },
  methods: {
    showTutorial() {
      this.$emit("close");
      localStorage.setItem("disable_tour", "false");
      if (this.$route.path == "/theses") {
        let oT = new OnboardTour(this, VOTOTour.Theses);
        oT.tour.start();
      } else if (this.$route.path == "/matches") {
        let oT = new OnboardTour(this, VOTOTour.Matches);
        oT.tour.start();
        // } else if (this.$route.path.includes("/match/")) {
        //   alert("Show detail match");
        //   let oT = new OnboardTour(this, VOTOTour.Match);
        //   oT.tour.start();
      }
    },
    changeLanguage(locale) {
      this.$emit("close");
      i18n.global.locale.value = locale;
    },
    async fetchLanguagesFromApi(id) {
      let locales = await this.fetchAvailableLanguages(id);
      for (const locale of locales) {
        let name;
        switch (locale) {
          case "de-DE-simple":
            name = this.$t("app.de_DE_simple");
            break;
          default:
            name = new Intl.DisplayNames([locale], {
              type: "language",
            }).of(locale);
        }
        this.availableLanguagesFromApi.push({
          value: locale,
          name: name,
        });
      }
    },
  },
  computed: {
    languageOptions() {
      return i18n.global.availableLocales.map((l) => {
        let name;
        switch (l) {
          case "de-DE-simple":
            name = this.$t("app.de_DE_simple");
            break;
          case "ro":
            name = this.$t("app.ro");
            break;
          default:
            name = new Intl.DisplayNames([l], { type: "language" }).of(l);
        }
        return {
          value: l,
          name,
        };
      });
    },
    filteredLanguageOptions() {
      if (!this.availableLanguagesFromApi.length) {
        return this.languageOptions;
      }
      // Check if the language is available in the API
      return this.languageOptions.filter((l) =>
        this.availableLanguagesFromApi.some((al) => al.value === l.value)
      );
    }
  },
  watch: {
    $route(to) {

      if (to.path.includes('app')) {
        this.fetchLanguagesFromApi(to.params["id"]);
      } 

      if (
        to.path == "/theses" ||
        to.path == "/matches"
        //to.path.includes("/match/")
      ) {
        this.showTutorialMenu = true;
      } else {
        this.showTutorialMenu = false;
      }
    },
  },
};
</script>

<style scoped>
#menu-items > * {
  border-bottom: 1px solid #d7d7d7;
  height: 2rem;
  margin-bottom: 1rem;
  text-decoration: none;
  color: #6c757d;
}

#menu-items > a {
  color: var(--p-primary-color);
}

.bottom {
  position: fixed;
  bottom: 10px;
  margin-left: -15px;
  width: 20rem;
}

p {
  margin-left: 0;
}

.version-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: gray;
  font-size: 0.8rem;
}
</style>
